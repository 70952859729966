import React, { useState ,useEffect } from 'react';
import '../css/Home.css';
import { useNavigate } from 'react-router-dom';
import tuvacolLogo from '../assets/logo_tuvacol.png';
import googleIcon from '../assets/logo_google.svg';
import image1 from '../assets/image4.png';
import image2 from '../assets/image1.png';
import { auth } from './FireBase';
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth';
import AccessModal from '../Components/AccessModal'; // Import your modal component

export default function Home() {
  const navigate = useNavigate();
  const provider = new GoogleAuthProvider();
  const [isModalVisible, setModalVisible] = useState(false); 
  const [currentImage, setCurrentImage] = useState(image1);
  
  useEffect(() => {
    const images = [image1, image2]; // Agrega más imágenes si es necesario
    let currentIndex = 0;

    // Establecemos el intervalo para cambiar la imagen
    const intervalId = setInterval(() => {
      // Cambiamos el índice de la imagen
      currentIndex = (currentIndex + 1) % images.length;
      setCurrentImage(images[currentIndex]);
    }, 5000); // 300000ms = 5 minutos

    // Limpiar el intervalo cuando el componente se desmonte
    return () => clearInterval(intervalId);
  }, []);

  // State to manage modal visibility

  function signIn() {
    signInWithPopup(auth, provider)
      .then((result) => {
        onAuthStateChanged(auth, (user) => {
          if (user) {
            const email = user.email;
            const dominio = "tuvacol.com";
            if (!email.endsWith(`@${dominio}`)) {
              signOut(auth).then(() => {
                console.log("Usuario desconectado por dominio no permitido");
                setModalVisible(true); // Show the denied access modal
              });
            } else {
              navigate('/dashboard');
            }
          }
        });
      })
      .catch((error) => {
        console.error("Error en el inicio de sesión: ", error);
      });
  }

  const handleCloseModal = () => {
    setModalVisible(false); // Close the modal
  };
  

  return (
    <div className="container">
      <AccessModal isVisible={isModalVisible} onClose={handleCloseModal} />
      <div className="logo-container">
      </div>
      <div className="left-section">
        <div className="title-home">
          <span className="title-normal">CENTRO DE</span>
          <span className="title-uppercase">CONTROL</span>
        </div>
        <div className="circle-grid">
          <div className="circle">
          <img src={currentImage} alt="Imagen Actual" className="img" />          
          </div>
        </div>
      </div>
      <div className="right-section">
        <div className="login-box">
          <img src={tuvacolLogo} alt="Tuvacol Logo" className="tuvacol-logo" />
          <h2 className='title-login'>BIENVENIDO</h2>
          <p className='title-sublogin'>Inicia sesión con tu cuenta para continuar</p>
          <button className="login-btn google-btn" onClick={signIn}>
            <img src={googleIcon} alt="Google Icon" className="google-icon" />
            Sign in with Google
          </button>
        </div>
      </div>
    </div>
  );
}
