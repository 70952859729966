import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { getOrdersData } from "../pages/FireBase";
import "../css/DashboardStats.css";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function DashboardStats() {
  const [chartData, setChartData] = useState({
    barranquilla: { niples: [], tuberia: [] },
    yumbo: [],
  });
  const [latestTickets, setLatestTickets] = useState({
    barranquilla: { niples: null, tuberia: null },
    yumbo: null,
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const niplesData = await getOrdersData("RequestNiples");
        const tuberiaData = await getOrdersData("RequestPaint");
        const yumboData = await getOrdersData("RequestNiples40");

        setChartData({
          barranquilla: { niples: niplesData, tuberia: tuberiaData },
          yumbo: yumboData,
        });

        const calculateLastTicket = (data) => {
          const approvedOrders = data.filter((order) => order.estado === "aprobado");
          if (approvedOrders.length === 0) return "N/A";
          return Math.max(...approvedOrders.map((order) => order.numeroTicket));
        };

        setLatestTickets({
          barranquilla: {
            niples: calculateLastTicket(niplesData),
            tuberia: calculateLastTicket(tuberiaData),
          },
          yumbo: calculateLastTicket(yumboData),
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  // Prepare combined donut data for Niples (Barranquilla + Yumbo)
  const prepareDonutDataNiples = (barranquillaNiples, yumboNiples) => ({
    labels: ["Niples Barranquilla", "Niples Yumbo"],
    datasets: [
      {
        data: [
          barranquillaNiples.filter((order) => order.estado === "aprobado").length || 0,
          yumboNiples.filter((order) => order.estado === "aprobado").length || 0,
        ],
        backgroundColor: ["#FFD400", "#004990"], // Different colors for each section
      },
    ],
  });

  return (
    <div className="dashboard-stats">
      <h2>RESUMEN OPERATIVO</h2>

      <div style={{ display: "flex", gap: "40px", justifyContent: "center",  marginTop:"-30px"}}>

        {/* Combined Niples Section (Barranquilla + Yumbo) */}
        <div style={{ width: "28%" }}>
          <h3>Niples</h3>
          <Doughnut
            data={prepareDonutDataNiples(chartData.barranquilla.niples, chartData.yumbo)}
            options={{
              responsive: true,
              plugins: {
                legend: { position: "bottom" },
                title: { display: true, text: "Órdenes Aprobadas - Niples (Barranquilla + Yumbo)" },
              },
            }}
          />
          <table>
            <thead>
              <tr>
                <th>Categoría</th>
                <th>Último Ticket Aprobado</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Niples Barranquilla</td>
                <td>{latestTickets.barranquilla.niples}</td>
              </tr>
              <tr>
                <td>Niples Yumbo</td>
                <td>{latestTickets.yumbo}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Barranquilla Section for Tubería */}
        <div style={{ width: "28%" }}>
          <h3>Tuberia</h3>
          <Doughnut
            data={{
              labels: ["Tubería"],
              datasets: [
                {
                  data: [chartData.barranquilla.tuberia.filter((order) => order.estado === "aprobado").length || 0],
                  backgroundColor: ["#004990"],
                },
              ],
            }}
            options={{
              responsive: true,
              plugins: {
                legend: { position: "bottom" },
                title: { display: true, text: "Órdenes Aprobadas - Tubería Barranquilla" },
              },
            }}
          />
          <table>
            <thead>
              <tr>
                <th>Categoría</th>
                <th>Último Ticket Aprobado</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Tubería</td>
                <td>{latestTickets.barranquilla.tuberia}</td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
  );
}
